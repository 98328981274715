import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

const MultiSelect: React.FC<SelectProps<number[]>> = props => (
  <Select<number[]>
    className='multi-select'
    mode='multiple'
    allowClear
    {...props}
  />
);

export default MultiSelect;
