import React, { forwardRef } from 'react';
import { Input } from 'antd';
import MaskedInput from 'antd-mask-input';
import { DayCheckTime, allDays } from '../api/types';

export interface Props {
  value?: DayCheckTime[];
  onChange?: Function;
  disabled?: boolean;
  type?: 'openTime' | 'closeTime';
}

const TimesPicker = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled, type }, ref) => {
    const timesType = type || 'closeTime';

    const handleChange = (val: string, index: number) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          valueClone[index][timesType] = val;
          onChange(valueClone);
        }
      }
    };

    return (
      <div className='times-picker' ref={ref}>
        {allDays.map((val, index) => {
          const isEnabled = value && value[index].checked;
          const innerDisabled = disabled || !isEnabled;
          let inputValue: string | undefined;
          if (isEnabled) {
            if (value) {
              inputValue = value[index][timesType];
            }
          } else {
            inputValue = 'x';
          }
          return innerDisabled ? (
            <Input
              key={val}
              disabled={innerDisabled}
              value={inputValue}
              onChange={e => handleChange(e.currentTarget.value, index)}
            />
          ) : (
            <MaskedInput
              mask='11:11'
              key={val}
              disabled={innerDisabled}
              value={inputValue}
              onChange={e => handleChange(e.currentTarget.value, index)}
            />
          );
        })}
      </div>
    );
  },
);

export default TimesPicker;
