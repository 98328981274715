import { AxiosPromise } from 'axios';
import http from './httpBase';
import { TransportDetail } from './types';
import { transportListData, transportData } from './mockData';

const { get, post, mock } = http;
const TRANSPORT_URL = '/transports';

const getTransportList = (): AxiosPromise<TransportDetail[]> =>
  get(`${TRANSPORT_URL}`);

mock.onGet(TRANSPORT_URL).reply(200, transportListData);

const createTransport = (
  body: TransportDetail,
): AxiosPromise<TransportDetail> => post(`${TRANSPORT_URL}`, body);

mock.onPost(TRANSPORT_URL).reply(200, transportData);

const getTransportDetail = (id: number): AxiosPromise<TransportDetail> =>
  get(`${TRANSPORT_URL}/${id}`);

mock.onGet(/\/transports\/\d+/).reply(200, transportData);

const updateTransport = (
  body: TransportDetail,
): AxiosPromise<TransportDetail> => post(`${TRANSPORT_URL}/${body.id}`, body);

mock.onPost(/\/transports\/\d+/).reply(200, transportData);

export default {
  getTransportList,
  createTransport,
  getTransportDetail,
  updateTransport,
};
