import { AxiosPromise } from 'axios';
import http from './httpBase';
import { Warehouse } from './types';
import { warehouseListData, warehouseData } from './mockData';

const { get, post, mock } = http;
const WAREHOUSE_URL = '/warehouses';

const getWarehouseList = (): AxiosPromise<Warehouse[]> =>
  get(`${WAREHOUSE_URL}`);

mock.onGet(WAREHOUSE_URL).reply(200, warehouseListData);

const createWarehouse = (body: Warehouse): AxiosPromise<Warehouse> =>
  post(`${WAREHOUSE_URL}`, body);

mock.onPost(WAREHOUSE_URL).reply(200, warehouseData);

const getWarehouseDetail = (id: number): AxiosPromise<Warehouse> =>
  get(`${WAREHOUSE_URL}/${id}`);

mock.onGet(/\/warehouses\/\d+/).reply(200, warehouseData);

const updateWarehouse = (body: Warehouse): AxiosPromise<Warehouse> =>
  post(`${WAREHOUSE_URL}/${body.id}`, body);

mock.onPost(/\/warehouses\/\d+/).reply(200, warehouseData);

export default {
  getWarehouseList,
  createWarehouse,
  getWarehouseDetail,
  updateWarehouse,
};
