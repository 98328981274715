import {
  CalendarListItem,
  CalendarDetail,
  Entity,
  Warehouse,
  Day,
  WarehouseTransportListItem,
  WarehouseTransport,
  PickupPointListItem,
  PickupPoint,
  CountryDetail,
  CalendarIn,
  UserResponse,
  TransportDetail,
} from './types';

export const calendarListData: CalendarListItem[] = [
  {
    id: 3,
    name: 'Svátky a víkendy',
  },
];

export const calendarData: CalendarIn = {
  id: 3,
  name: 'Svátky a víkendy',
  days: ['2019-11-12', '2019-11-13'],
};

export const calendarDetailData: CalendarDetail = {
  id: 3,
  name: 'Svátky a víkendy',
  days: ['2019-11-12', '2019-11-13'],
  assignedTo: [
    {
      entity: Entity.WAREHOUSE,
      name: 'PKL',
    },
  ],
};
export const warehouseData: Warehouse = {
  id: 2,
  name: 'PKL',
  active: true,
  country: {
    id: 2,
    code: 'CZE',
  },
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  operationDays: [Day.MONDAY, Day.FRIDAY, Day.SATURDAY],
  stockingDelay: 2,
  expeditionDelay: 1,
  heliosSupply: true,
  stockCloseTime: '15:00',
  releaseDateDelay: 5,
};

export const warehouseListData: Warehouse[] = [1, 2, 3, 4, 5, 6].map(v => ({
  ...warehouseData,
  id: v,
  active: v % 2 === 0,
}));

export const warehouseTransportListData: WarehouseTransportListItem[] = [
  1,
  2,
  3,
  4,
  5,
].map(v => ({
  id: v,
  warehouse: {
    id: 2,
    name: 'PKL',
  },
  transport: {
    id: 2,
    name: 'PPL',
  },
  deliveryDays: [Day.MONDAY, Day.THURSDAY],
  expeditionDays: [
    {
      day: Day.MONDAY,
      closeTime: '17:30',
    },
    {
      day: Day.TUESDAY,
      closeTime: '17:30',
    },
    {
      day: Day.WEDNESDAY,
      closeTime: '17:30',
    },
    {
      day: Day.THURSDAY,
      closeTime: '17:30',
    },
    {
      day: Day.FRIDAY,
      closeTime: '17:30',
    },
  ],
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  country: {
    id: 2,
    code: 'CZE',
  },
  zips: [1, 2, 3, 4, 5].map(i => ({
    id: i,
    deliveryDuration: 1,
    zipFrom: 11000,
    zipTo: 12000,
    expeditionDays: [
      {
        day: Day.MONDAY,
        closeTime: '08:30',
      },
    ],
  })),
}));

export const warehouseTransportData: WarehouseTransport = {
  id: 2,
  warehouse: {
    id: 2,
    name: 'PKL',
  },
  transport: {
    id: 2,
    name: 'PPL',
  },
  deliveryDays: [Day.MONDAY],
  expeditionDays: [
    {
      day: Day.MONDAY,
      closeTime: '17:30',
    },
  ],
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  zips: [1, 2, 3, 4, 5].map(v => ({
    id: v,
    deliveryDuration: 1,
    zipFrom: 11000,
    zipTo: 12000,
    expeditionDays: [
      {
        day: Day.MONDAY,
        closeTime: '08:30',
      },
    ],
  })),
  pickupPoints: [
    {
      id: 2,
      name: 'OC Futurum',
    },
  ],
  country: {
    id: 2,
    code: 'CZE',
  },
};

export const pickupPointListData: PickupPointListItem[] = [
  1,
  2,
  3,
  4,
  5,
  6,
].map(v => ({
  id: v,
  name: 'OC Futurum',
  active: v % 2 === 0,
  openDays: [
    {
      day: Day.MONDAY,
      closeTime: '17:30',
      openTime: '08:00',
    },
    {
      day: Day.TUESDAY,
      closeTime: '17:30',
      openTime: '09:00',
    },
    {
      day: Day.FRIDAY,
      closeTime: '16:00',
      openTime: '08:00',
    },
  ],
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  warehouseStockingDelay: 1,
  warehouseExpeditionDelay: 0,
  pupExpeditionDelay: 1,
  warehouseReleaseDelay: 1,
}));

export const pickupPointData: PickupPoint = {
  id: 2,
  name: 'OC Futurum',
  active: true,
  openDays: [
    {
      day: Day.MONDAY,
      closeTime: '17:30',
      openTime: '08:00',
    },
    {
      day: Day.TUESDAY,
      closeTime: '17:30',
      openTime: '08:00',
    },
    {
      day: Day.FRIDAY,
      closeTime: '16:00',
      openTime: '08:00',
    },
  ],
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  pupExpeditionDelay: 1,
  warehouseId: 5,
  warehouseStockingDelay: 1,
  warehouseExpeditionDelay: 0,
  warehouseOpenDays: [Day.MONDAY],
  warehouseCalendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
  zipCode: 12345,
  warehouseReleaseDelay: 5,
};

export const countryData: CountryDetail = {
  id: 1,
  code: 'CZE',
  calendar: {
    id: 3,
    name: 'Svátky a víkendy',
  },
};

export const countryListData: CountryDetail[] = [
  {
    id: 1,
    code: 'CZE',
    calendar: {
      id: 3,
      name: 'Svátky a víkendy',
    },
  },
  {
    id: 2,
    code: 'SVK',
    calendar: {
      id: 3,
      name: 'Svátky a víkendy',
    },
  },
  {
    id: 3,
    code: 'GER',
    calendar: {
      id: 3,
      name: 'Svátky a víkendy',
    },
  },
];

export const transportListData: TransportDetail[] = [
  {
    id: 1,
    name: 'PPL',
    active: true,
    onlyPupDelivery: false,
  },
  {
    id: 2,
    name: 'Česká pošta',
    active: false,
    onlyPupDelivery: false,
  },
  {
    id: 3,
    name: 'Osobní vyzvednutí',
    active: true,
    onlyPupDelivery: true,
  },
];

export const transportData: TransportDetail = {
  id: 1,
  name: 'PPL',
  active: true,
  onlyPupDelivery: false,
};

export const userData: UserResponse = {
  id: 1,
  login: 'ferdam',
  name: 'Ferda Mravenec',
};
