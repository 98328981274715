import React from 'react';
import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import translations from '../translations';
import { useStoreState } from '../store/hooks';

const LanguageProvider: React.FC = ({ children }) => {
  const locale = useStoreState(state => state.user.locale);
  return (
    <IntlProvider locale={locale} messages={flatten(translations[locale])}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
