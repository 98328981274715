import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useStoreState } from '../store/hooks';
import { LOGIN } from './Routes';

const PrivateRoute: React.FC<RouteProps> = props => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
  return isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: LOGIN,
        state: { from: props.location },
      }}
    />
  );
};

export default PrivateRoute;
