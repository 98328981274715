import { AxiosPromise } from 'axios';
import http from './httpBase';
import { CalendarListItem, CalendarIn, CalendarDetail } from './types';
import { calendarListData, calendarData, calendarDetailData } from './mockData';

const { get, post, mock } = http;
const CALENDAR_URL = '/calendars';

const getCalendarList = (
  countryIds?: number[],
): AxiosPromise<CalendarListItem[]> =>
  get(`${CALENDAR_URL}`, { params: { countryIds } });

mock.onGet(CALENDAR_URL).reply(200, calendarListData);

const createCalendar = (body: CalendarIn): AxiosPromise<CalendarIn> =>
  post(`${CALENDAR_URL}`, body);

mock.onPost(CALENDAR_URL).reply(200, calendarData);

const getCalendarDetail = (id: number): AxiosPromise<CalendarDetail> =>
  get(`${CALENDAR_URL}/${id}`);

mock.onGet(/\/calendars\/\d+/).reply(200, calendarDetailData);

const updateCalendar = (body: CalendarIn): AxiosPromise<CalendarDetail> =>
  post(`${CALENDAR_URL}/${body.id}`, body);

mock.onPost(/\/calendars\/\d+/).reply(200, calendarDetailData);

export default {
  getCalendarList,
  createCalendar,
  getCalendarDetail,
  updateCalendar,
};
