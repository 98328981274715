import React from 'react';
import { Icon, Layout, Menu } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  CALENDARS,
  WAREHOUSES,
  TRANSPORT_DEFINITIONS,
  PUP_DEFINITIONS,
} from '../routes/Routes';
import { useStoreState, useStoreActions } from '../store/hooks';

function getTitleLink(path: string): string {
  switch (path) {
    case CALENDARS:
      return 'calendars';
    case WAREHOUSES:
      return 'warehouses';
    case TRANSPORT_DEFINITIONS:
      return 'transport_definitions';
    case PUP_DEFINITIONS:
      return 'pup_definitions';
    default:
      return '';
  }
}

export interface Props {}

const HeaderBar: React.FC<Props> = () => {
  const collapsed = useStoreState(state => state.general.collapsed);
  const toggle = useStoreActions(actions => actions.general.setCollapsed);
  const { pathname } = useLocation();
  const { isLoggedIn, name, username } = useStoreState(state => state.user);
  return isLoggedIn ? (
    <Layout.Header className='header'>
      <Menu mode='horizontal'>
        <Menu.Item disabled className='no-padding'>
          <Icon
            className='trigger'
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={() => toggle(!collapsed)}
          />
          <h2 className='title'>
            {getTitleLink(pathname) !== '' && (
              <FormattedMessage id={`side_bar.${getTitleLink(pathname)}`} />
            )}
          </h2>
        </Menu.Item>
        <Menu.SubMenu
          title={
            <span className='submenu-title-wrapper'>
              <i className='fas fa-user' style={{ marginRight: 6 }} />
              {`[${username}] ${name}`}
            </span>
          }
          className='user-menu'
        >
          <Menu.Item>
            <Link to='/logout'>
              <Icon type='logout' />
              <FormattedMessage id='header.logout' />
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  ) : null;
};

export default HeaderBar;
