import React, { useEffect } from 'react';
import { Form, Input, Switch, Select, InputNumber, Row, Col, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { PickupPoint, allDays, PickupPointIn } from '../api/types';
import { useStoreState, useStoreActions } from '../store/hooks';
import DaysPicker from './DaysPicker';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';
import TimesPicker from './TimesPicker';

interface Props extends FormComponentProps<PickupPointIn> {
  // eslint-disable-next-line react/no-unused-prop-types
  data: PickupPoint;
  mode: Mode;
}

const PuPForm: React.FC<Props> = ({ form: { getFieldDecorator }, mode }) => {
  const isView = mode === Mode.VIEW;
  const isCreate = mode === Mode.CREATE;
  const calendars = useStoreState(state => state.calendars.list);
  const countries = useStoreState(state => state.countries.list);
  const fetchCalendars = useStoreActions(
    actions => actions.calendars.fetchList,
  );
  const fetchCountries = useStoreActions(
    actions => actions.countries.fetchList,
  );

  useEffect(() => {
    fetchCalendars({ ids: [] });
    fetchCountries();
  }, [fetchCalendars, fetchCountries]);

  getFieldDecorator('id');
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} className='pup-form'>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id='warehouses.detail.name' />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<Input disabled={isView} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id='warehouses.detail.active' />}>
            {getFieldDecorator('active', {
              valuePropName: 'checked',
            })(<Switch disabled={isView} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={<FormattedMessage id='pup_definitions.detail.country' />}
          >
            {getFieldDecorator('country.id', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <Select disabled={isView}>
                {countries
                  .valueSeq()
                  .toArray()
                  .map(({ code, id }) => (
                    <Select.Option key={id} value={id}>
                      {code}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-around' align='middle'>
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane
            tab={<FormattedMessage id='pup_definitions.detail.tab1' />}
            key='1'
          >
            <Form.Item
              label={<FormattedMessage id='pup_definitions.detail.postcode' />}
            >
              {getFieldDecorator('zipCode')(<InputNumber disabled={isView} />)}
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='pup_definitions.detail.open_days' />}
            >
              {getFieldDecorator('openDays')(<DaysPicker disabled={isView} />)}
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='pup_definitions.detail.calendar' />}
            >
              {getFieldDecorator('warehouseCalendar.id', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='validations.required' />,
                  },
                ],
              })(
                <Select disabled={isView}>
                  {calendars
                    .valueSeq()
                    .toArray()
                    .map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.open_times' />
              }
            >
              {getFieldDecorator('openDays')(
                <TimesPicker type='openTime' disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.close_times' />
              }
            >
              {getFieldDecorator('openDays')(<TimesPicker disabled={isView} />)}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.expedition_delay' />
              }
            >
              {getFieldDecorator('pupExpeditionDelay')(
                <InputNumber disabled={isView} />,
              )}
              <span className='minutes'>
                <FormattedMessage id='pup_definitions.detail.minutes' />
              </span>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<FormattedMessage id='pup_definitions.detail.tab2' />}
            key='2'
          >
            {!isCreate && (
              <Form.Item
                label={
                  <FormattedMessage id='pup_definitions.detail.warehouseId' />
                }
              >
                {getFieldDecorator('warehouseId')(<InputNumber disabled />)}
              </Form.Item>
            )}
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.operation_days' />
              }
            >
              {getFieldDecorator('warehouseOpenDays')(
                <DaysPicker disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='pup_definitions.detail.calendar' />}
            >
              {getFieldDecorator('calendar.id', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='validations.required' />,
                  },
                ],
              })(
                <Select disabled={isView}>
                  {calendars
                    .valueSeq()
                    .toArray()
                    .map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.stocking_delay' />
              }
            >
              {getFieldDecorator('warehouseStockingDelay')(
                <InputNumber disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.expedition_delay' />
              }
            >
              {getFieldDecorator('warehouseExpeditionDelay')(
                <InputNumber disabled={isView} />,
              )}
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id='pup_definitions.detail.warehouse_release_delay' />
              }
            >
              {getFieldDecorator('warehouseReleaseDelay')(
                <InputNumber disabled={isView} />,
              )}
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data: { openDays, warehouseOpenDays, ...rest } }) =>
    transformFormData({
      openDays: allDays.map(v => {
        const index = openDays.findIndex(val => val.day === v);
        const isChecked = index !== -1;
        return {
          checked: isChecked,
          day: v,
          closeTime: isChecked ? openDays[index].closeTime : 'x',
          openTime: isChecked ? openDays[index].openTime : 'x',
        };
      }),
      warehouseOpenDays: allDays.map(v => ({
        day: v,
        checked: warehouseOpenDays.includes(v),
      })),
      ...rest,
    }),
})(PuPForm);
