import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NewButton from '../components/NewButton';
import { useStoreActions, useStoreState } from '../store/hooks';
import WarehouseTable from '../components/WarehouseTable';
import { WarehouseIn } from '../api/types';
import WarehouseForm from '../components/WarehouseForm';
import { Mode } from '../store/types';
import useFormRef from '../hooks/useFormRef';
import { defaultWarehouse } from '../store/warehouses';

const WarehousePage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const detail = useStoreState(state => state.warehouses.detail);
  const {
    fetchList,
    createWarehouse,
    updateWarehouse,
    fetchWarehouse,
    setDetail,
  } = useStoreActions(actions => actions.warehouses);

  const { saveFormRef, formRef } = useFormRef<WarehouseIn>();

  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleEdit = async (id: number) => {
    await fetchWarehouse(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchWarehouse(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultWarehouse);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    formRef!.validateFields((err, values) => {
      if (!err) {
        const inValues = {
          ...values,
          operationDays: values.operationDays
            .filter(v => v.checked)
            .map(v => v.day),
        };
        switch (mode) {
          case Mode.CREATE:
            createWarehouse(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_new' }),
                );
                setVisible(false);
              }
            });
            break;
          case Mode.EDIT:
            updateWarehouse(inValues).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_edit' }),
                );
                setVisible(false);
              }
            });
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <div className='warehouses'>
      <NewButton onClick={handleNew} />
      <WarehouseTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={<FormattedMessage id='warehouses.detail.title' />}
        visible={isVisible}
        footer={mode === Mode.VIEW ? null : undefined}
        onCancel={e => {
          e.preventDefault();
          setVisible(false);
          formRef!.resetFields();
        }}
        okText={<FormattedMessage id='common.save' />}
        onOk={handleSave}
        width={550}
      >
        <WarehouseForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};

export default WarehousePage;
