import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { Country, CountryDetail, BaseEntity } from '../api/types';
import { StoreModel } from './types';
import { countryApi } from '../api';

export const defaultCountry: CountryDetail = {
  id: 0,
  code: '',
  calendar: {} as BaseEntity,
};

export interface CountryStore {
  isLoading: boolean;
  list: OrderedMap<number, Country>;
  listDetail: OrderedMap<number, CountryDetail>;
  detail: CountryDetail;
  setLoading: Action<CountryStore, boolean>;
  setList: Action<CountryStore, Country[]>;
  setListDetail: Action<CountryStore, CountryDetail[]>;
  setDetail: Action<CountryStore, CountryDetail>;
  fetchList: Thunk<CountryStore, void, any, StoreModel>;
  fetchCountry: Thunk<CountryStore, number, any, StoreModel, Promise<boolean>>;
  createCountry: Thunk<
    CountryStore,
    CountryDetail,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateCountry: Thunk<
    CountryStore,
    CountryDetail,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const countries: CountryStore = {
  isLoading: false,
  list: OrderedMap(),
  listDetail: OrderedMap(),
  detail: defaultCountry,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setListDetail: action((state, payload) => {
    state.listDetail = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await countryApi.getCountryList();
      actions.setList(data);
      actions.setListDetail(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchCountry: thunk(async (actions, id) => {
    try {
      const { data } = await countryApi.getCountryDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createCountry: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await countryApi.createCountry(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  updateCountry: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await countryApi.updateCountry(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default countries;
