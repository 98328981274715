import React, { forwardRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Select, Table, Icon } from 'antd';
import { BaseEntity, PickupPointListItem } from '../api/types';
import { pickupPointApi } from '../api';

export interface Props {
  value?: BaseEntity[];
  onChange?: Function;
  disabled?: boolean;
}

const PuPSelect = forwardRef<Select<number>, Props>(
  ({ value, onChange, disabled }, ref) => {
    const [data, setData] = useState<PickupPointListItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { formatMessage } = useIntl();

    const handleSearch = async (searchVal?: string) => {
      setLoading(true);
      try {
        const { data: d } = await pickupPointApi.getPickupPointList(searchVal);
        setData(d.filter(v => v.active));
      } catch (error) {
        //
      }
      setLoading(false);
    };

    const handleRemove = (val: number) => {
      if (onChange && value) {
        onChange(value.filter(v => v.id !== val));
      }
    };

    const handleChange = async (val: number) => {
      if (onChange) {
        if (value) {
          const listInd = value.findIndex(v => v.id === val);
          const dataInd = data.findIndex(v => v.id === val);
          if (listInd === -1) {
            onChange([...value, { id: val, name: data[dataInd].name }]);
          } else {
            handleRemove(val);
          }
        }
      }
    };

    useEffect(() => {
      handleSearch();
    }, []);

    return (
      <>
        {!disabled && (
          <Select<number>
            className='new-button'
            placeholder={formatMessage({
              id: 'pup_definitions.search_placeholder',
            })}
            showSearch
            loading={loading}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            ref={ref}
          >
            {data
              .filter(
                v => value && value.findIndex(val => val.id === v.id) === -1,
              )
              .map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        )}
        <Table
          dataSource={value}
          rowKey='id'
          size='middle'
          columns={[
            {
              dataIndex: 'id',
              title: formatMessage({
                id: 'transport_definitions.detail.pups.id',
              }),
            },
            {
              dataIndex: 'name',
              title: formatMessage({
                id: 'transport_definitions.detail.pups.name',
              }),
            },
            {
              key: 'delete',
              render: (text, { id }) =>
                disabled ? null : (
                  <Icon
                    className='delete-icon'
                    onClick={e => {
                      e.preventDefault();
                      handleRemove(id);
                    }}
                    type='delete'
                  />
                ),
              align: 'right',
            },
          ]}
        />
      </>
    );
  },
);

export default PuPSelect;
