import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { EntityWithName } from '../api/types';

export interface Props {
  dataSource: EntityWithName[];
}

const EntityTable: React.FC<Props> = ({ dataSource }) => {
  const { formatMessage } = useIntl();
  return (
    <Table<EntityWithName>
      dataSource={dataSource}
      size='small'
      rowKey={({ entity, name }) => `${entity}-${name}`}
      columns={[
        {
          dataIndex: 'name',
          title: formatMessage({ id: 'calendars.detail.entity.name' }),
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          dataIndex: 'entity',
          title: formatMessage({ id: 'calendars.detail.entity.entity' }),
          sorter: (a, b) => a.entity.localeCompare(b.entity),
        },
      ]}
    />
  );
};

export default EntityTable;
