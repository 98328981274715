import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormattedMessage } from 'react-intl';

const UpdateButton: React.FC<ButtonProps> = props => (
  <Button
    type='link'
    {...props}
    onClick={e => {
      e.stopPropagation();
      if (props.onClick) {
        props.onClick(e);
      }
    }}
  >
    <FormattedMessage id='common.update' />
  </Button>
);
export default UpdateButton;
