import { AxiosPromise } from 'axios';
import http from './httpBase';
import { CountryDetail } from './types';
import { countryListData, countryData } from './mockData';

const { get, post, mock } = http;
const COUNTRY_URL = '/countries';

const getCountryList = (): AxiosPromise<CountryDetail[]> =>
  get(`${COUNTRY_URL}`);

mock.onGet(COUNTRY_URL).reply(200, countryListData);

const createCountry = (body: CountryDetail): AxiosPromise<CountryDetail> =>
  post(`${COUNTRY_URL}`, body);

mock.onPost(COUNTRY_URL).reply(200, countryData);

const getCountryDetail = (id: number): AxiosPromise<CountryDetail> =>
  get(`${COUNTRY_URL}/${id}`);

mock.onGet(/\/countries\/\d+/).reply(200, countryData);

const updateCountry = (body: CountryDetail): AxiosPromise<CountryDetail> =>
  post(`${COUNTRY_URL}/${body.id}`, body);

mock.onPost(/\/countries\/\d+/).reply(200, countryData);

export default {
  getCountryList,
  createCountry,
  getCountryDetail,
  updateCountry,
};
