import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { PickupPointListItem, PickupPoint, BaseEntity } from '../api/types';
import { StoreModel } from './types';
import { pickupPointApi } from '../api';

export const defaultPuP: PickupPoint = {
  id: 0,
  active: true,
  calendar: {} as BaseEntity,
  name: '',
  openDays: [],
  pupExpeditionDelay: 0,
  warehouseId: 0,
  warehouseCalendar: {} as BaseEntity,
  warehouseOpenDays: [],
  warehouseStockingDelay: 0,
  warehouseExpeditionDelay: 0,
  zipCode: 0,
  warehouseReleaseDelay: 0,
};

export interface PickupPointStore {
  isLoading: boolean;
  list: OrderedMap<number, PickupPointListItem>;
  detail: PickupPoint;
  searchValue: string;
  setLoading: Action<PickupPointStore, boolean>;
  setList: Action<PickupPointStore, PickupPointListItem[]>;
  setDetail: Action<PickupPointStore, PickupPoint>;
  setSearchValue: Action<PickupPointStore, string>;
  fetchList: Thunk<PickupPointStore, void, any, StoreModel>;
  fetchPuP: Thunk<PickupPointStore, number, any, StoreModel, Promise<boolean>>;
  createPuP: Thunk<
    PickupPointStore,
    PickupPoint,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updatePuP: Thunk<
    PickupPointStore,
    PickupPoint,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const pickupPoints: PickupPointStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultPuP,
  searchValue: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  setSearchValue: action((state, payload) => {
    state.searchValue = payload;
  }),
  fetchList: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setLoading(true);
      const { searchValue } = getState();
      const { data } = await pickupPointApi.getPickupPointList(searchValue);
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchPuP: thunk(async (actions, id) => {
    try {
      const { data } = await pickupPointApi.getPickupPointDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createPuP: thunk(async (actions, value) => {
    try {
      await pickupPointApi.createPickupPoint(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updatePuP: thunk(async (actions, value) => {
    try {
      await pickupPointApi.updatePickupPoint(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
};

export default pickupPoints;
