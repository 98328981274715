import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import MaskedInput from 'antd-mask-input';
import { FormattedMessage } from 'react-intl';
import { CountryDetail } from '../api/types';
import { useStoreState, useStoreActions } from '../store/hooks';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';

interface Props extends FormComponentProps<CountryDetail> {
  data: CountryDetail;
  mode: Mode;
}

const CountryForm: React.FC<Props> = ({
  form: { getFieldDecorator },
  mode,
}) => {
  const isView = mode === Mode.VIEW;
  const calendars = useStoreState(state => state.calendars.list);
  const fetchCalendars = useStoreActions(
    actions => actions.calendars.fetchList,
  );

  useEffect(() => {
    fetchCalendars({});
  }, [fetchCalendars]);

  getFieldDecorator('id');
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Form.Item
        wrapperCol={{ span: 3 }}
        label={<FormattedMessage id='countries.detail.code' />}
      >
        {getFieldDecorator('code', {
          rules: [
            {
              required: true,
              message: <FormattedMessage id='validations.required' />,
            },
          ],
        })(<MaskedInput mask='AAA' disabled={isView} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage id='countries.detail.calendar' />}>
        {getFieldDecorator('calendar.id', {
          rules: [
            {
              required: true,
              message: <FormattedMessage id='validations.required' />,
            },
          ],
        })(
          <Select disabled={isView}>
            {calendars
              .valueSeq()
              .toArray()
              .map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
          </Select>,
        )}
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data }) =>
    transformFormData({
      ...data,
    }),
})(CountryForm);
