import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { CalendarListItem, CalendarDetail, CalendarIn } from '../api/types';
import { StoreModel } from './types';
import { calendarApi } from '../api';

export const defaultCalendar: CalendarDetail = {
  id: 0,
  name: '',
  days: [],
  assignedTo: [],
};

export interface CalendarStore {
  isLoading: boolean;
  list: OrderedMap<number, CalendarListItem>;
  countryIds: number[];
  detail: CalendarDetail;
  setLoading: Action<CalendarStore, boolean>;
  setList: Action<CalendarStore, CalendarListItem[]>;
  setCountryIds: Action<CalendarStore, number[]>;
  setDetail: Action<CalendarStore, CalendarDetail>;
  fetchList: Thunk<CalendarStore, { ids?: number[] }, any, StoreModel>;
  fetchCalendar: Thunk<
    CalendarStore,
    number,
    any,
    StoreModel,
    Promise<boolean>
  >;
  createCalendar: Thunk<
    CalendarStore,
    CalendarIn,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateCalendar: Thunk<
    CalendarStore,
    CalendarIn,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const calendars: CalendarStore = {
  isLoading: false,
  list: OrderedMap(),
  countryIds: [],
  detail: defaultCalendar,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setCountryIds: action((state, payload) => {
    state.countryIds = payload;
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async (actions, { ids }, { getState }) => {
    try {
      actions.setLoading(true);
      const { countryIds } = getState();
      const { data } = await calendarApi.getCalendarList(ids || countryIds);
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  fetchCalendar: thunk(async (actions, id) => {
    try {
      const { data } = await calendarApi.getCalendarDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createCalendar: thunk(async (actions, value) => {
    try {
      await calendarApi.createCalendar(value);
      await actions.fetchList({});
    } catch (error) {
      return false;
    }
    return true;
  }),
  updateCalendar: thunk(async (actions, value) => {
    try {
      await calendarApi.updateCalendar(value);
      await actions.fetchList({});
    } catch (error) {
      return false;
    }
    return true;
  }),
};

export default calendars;
