import { AxiosPromise } from 'axios';
import http from './httpBase';
import { WarehouseTransport, WarehouseTransportListItem } from './types';
import { warehouseTransportData, warehouseTransportListData } from './mockData';

const { get, post, mock } = http;
const WAREHOUSE_TRANSPORT_URL = '/warehouse-transports';

const getWarehouseTransportList = (
  warehouseIds: number[],
): AxiosPromise<WarehouseTransportListItem[]> =>
  get(`${WAREHOUSE_TRANSPORT_URL}`, {
    params: { warehouseIds },
  });

mock.onGet(WAREHOUSE_TRANSPORT_URL).reply(200, warehouseTransportListData);

const createWarehouseTransport = (
  body: WarehouseTransport,
): AxiosPromise<WarehouseTransport> => post(`${WAREHOUSE_TRANSPORT_URL}`, body);

mock.onPost(WAREHOUSE_TRANSPORT_URL).reply(200, warehouseTransportData);

const getWarehouseTransportDetail = (
  id: number,
): AxiosPromise<WarehouseTransport> => get(`${WAREHOUSE_TRANSPORT_URL}/${id}`);

mock.onGet(/\/warehouse-transports\/\d+/).reply(200, warehouseTransportData);

const updateWarehouseTransport = (
  body: WarehouseTransport,
): AxiosPromise<WarehouseTransport> =>
  post(`${WAREHOUSE_TRANSPORT_URL}/${body.id}`, body);

mock.onPost(/\/warehouse-transports\/\d+/).reply(200, warehouseTransportData);

export default {
  getWarehouseTransportList,
  createWarehouseTransport,
  getWarehouseTransportDetail,
  updateWarehouseTransport,
};
