import React, { forwardRef, useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import Calendar from './Calendar';

export interface CalendarDataSourceElement {
  color?: string;
  endDate: Date;
  endHalfDay?: boolean;
  name?: string;
  startDate: Date;
  startHalfDay?: boolean;
}

export interface Props {
  dataSource?: CalendarDataSourceElement[];
  style?: 'background' | 'border' | 'custom';
  onYearChanged?: (param: { currentYear: number }) => void;
  onDayClick?: (params: { date: Date }) => void;
  enableRangeSelection?: boolean;
  enableContextMenu?: boolean;
  contextMenuItems?: React.ReactNode;
  onRangeSelected?: (params: { startDate: Date; endDate: Date }) => void;
}

export const YearCalendar: React.FC<Props> = props => <Calendar {...props} />;

export interface YearCalendarInputProps {
  value?: string[];
  onChange?: Function;
  disabled?: boolean;
}

const YearCalendarInput = forwardRef<HTMLDivElement, YearCalendarInputProps>(
  ({ value, disabled, onChange }, ref) => {
    const [year, setYear] = useState(new Date().getFullYear());
    function handlePrevYear(e: React.MouseEvent<HTMLElement, MouseEvent>) {
      e.preventDefault();
      setYear(year - 1);
    }

    function handleNextYear(e: React.MouseEvent<HTMLElement, MouseEvent>) {
      e.preventDefault();
      setYear(year + 1);
    }
    return (
      <div ref={ref}>
        <Button className='prevYear' onClick={handlePrevYear}>
          Prev Year
        </Button>
        <Button className='nextYear' onClick={handleNextYear}>
          Next Year
        </Button>
        <YearCalendar
          dataSource={value?.map(v => {
            const date = new Date(v);
            return {
              startDate: date,
              endDate: date,
              color: '#ff0000',
            };
          })}
          onDayClick={(params: { date: Date }) => {
            if (value && onChange && !disabled) {
              const stringDate = moment(params.date).format('YYYY-MM-DD');

              if (value.includes(stringDate)) {
                onChange([value.filter(d => d !== stringDate)]);
              } else {
                onChange([...value, stringDate]);
              }
            }
          }}
        />
      </div>
    );
  },
);

export default YearCalendarInput;
