import { AxiosPromise } from 'axios';
import http from './httpBase';
import { PickupPointListItem, PickupPoint } from './types';
import { pickupPointListData, pickupPointData } from './mockData';

const { get, post, mock } = http;
const PICKUP_POINT_URL = '/pickup-points';

const getPickupPointList = (
  name?: string,
): AxiosPromise<PickupPointListItem[]> =>
  get(`${PICKUP_POINT_URL}`, { params: { name } });

mock.onGet(PICKUP_POINT_URL).reply(200, pickupPointListData);

const createPickupPoint = (body: PickupPoint): AxiosPromise<PickupPoint> =>
  post(`${PICKUP_POINT_URL}`, body);

mock.onPost(PICKUP_POINT_URL).reply(200, pickupPointData);

const getPickupPointDetail = (id: number): AxiosPromise<PickupPoint> =>
  get(`${PICKUP_POINT_URL}/${id}`);

mock.onGet(/\/pickup-points\/\d+/).reply(200, pickupPointData);

const updatePickupPoint = (body: PickupPoint): AxiosPromise<PickupPoint> =>
  post(`${PICKUP_POINT_URL}/${body.id}`, body);

mock.onPost(/\/pickup-points\/\d+/).reply(200, pickupPointData);

export default {
  getPickupPointList,
  createPickupPoint,
  getPickupPointDetail,
  updatePickupPoint,
};
