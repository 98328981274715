export enum Entity {
  WAREHOUSE = 'Warehouse',
  WAREHOUSE_TRANSPORT = 'WarehouseTransport',
  COUNTRY = 'Country',
  PICKUP_POINT = 'PickupPoint',
}

export enum Day {
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
  SUNDAY = '7',
}

export const allDays = [
  Day.MONDAY,
  Day.TUESDAY,
  Day.WEDNESDAY,
  Day.THURSDAY,
  Day.FRIDAY,
  Day.SATURDAY,
  Day.SUNDAY,
];

export type BaseEntity = {
  id: number;
  name: string;
};

export type Country = Omit<CountryDetail, 'calendar'>;

export type CountryDetail = {
  id: number;
  code: string;
  calendar: BaseEntity;
};

export type CalendarListItem = BaseEntity;

export type CalendarIn = Omit<CalendarDetail, 'assignedTo'>;

export type EntityWithName = { entity: Entity; name: string };

export type CalendarDetail = {
  id: number;
  name: string;
  days: string[];
  assignedTo: EntityWithName[];
};

export type DayCheckTime = {
  checked: boolean;
  day: Day;
  closeTime: string;
  openTime?: string;
};

export type DayCheck = Omit<DayCheckTime, 'closeTime'>;

export type Warehouse = {
  id: number;
  name: string;
  active: boolean;
  country: Country;
  calendar: CalendarListItem;
  operationDays: Day[];
  stockingDelay: number;
  expeditionDelay: number;
  heliosSupply: boolean;
  stockCloseTime: string;
  releaseDateDelay: number;
};

export type WarehouseIn = {
  id: number;
  name: string;
  active: boolean;
  country: Country;
  calendar: CalendarListItem;
  operationDays: DayCheck[];
  stockingDelay: number;
  expeditionDelay: number;
  heliosSupply: boolean;
  stockCloseTime: string;
  releaseDateDelay: number;
};

export type Zip = {
  id: number;
  deliveryDuration: number;
  zipFrom: number;
  zipTo: number;
  expeditionDays: ExpeditionDay[];
};

export type ExpeditionDay = {
  day: Day;
  closeTime: string;
};

export type WarehouseTransport = {
  id: number;
  warehouse: BaseEntity;
  transport: BaseEntity;
  deliveryDays: Day[];
  expeditionDays: ExpeditionDay[];
  calendar: BaseEntity;
  zips: Zip[];
  pickupPoints: BaseEntity[];
  country: Country;
};

export type WarehouseTransportIn = {
  id: number;
  warehouse: BaseEntity;
  transport: BaseEntity;
  deliveryDays: DayCheck[];
  expeditionDays: DayCheckTime[];
  calendar: BaseEntity;
  zips: Zip[];
  pickupPoints: BaseEntity[];
  country: Country;
};

export type WarehouseTransportListItem = Omit<
  WarehouseTransport,
  'pickupPoints'
>;

export type PickupPoint = {
  id: number;
  name: string;
  active: boolean;
  openDays: {
    day: Day;
    closeTime: string;
    openTime?: string;
  }[];
  calendar: BaseEntity;
  pupExpeditionDelay: number;
  warehouseId: number;
  warehouseStockingDelay: number;
  warehouseExpeditionDelay: number;
  warehouseOpenDays: Day[];
  warehouseCalendar: BaseEntity;
  zipCode: number;
  warehouseReleaseDelay: number;
};

export type PickupPointListItem = Omit<
  PickupPoint,
  'warehouseId' | 'warehouseOpenDays' | 'warehouseCalendar' | 'zipCode'
>;

export type PickupPointIn = {
  id: number;
  name: string;
  active: boolean;
  openDays: DayCheckTime[];
  calendar: BaseEntity;
  pupExpeditionDelay: number;
  warehouseId: number;
  warehouseStockingDelay: number;
  warehouseExpeditionDelay: number;
  warehouseOpenDays: DayCheck[];
  warehouseCalendar: BaseEntity;
  zipCode: number;
  warehouseReleaseDelay: number;
};

export type Transport = Omit<TransportDetail, 'active' | 'onlyPupDelivery'>;

export type TransportDetail = {
  id: number;
  name: string;
  active: boolean;
  onlyPupDelivery: boolean;
};

export type UserResponse = {
  id: number;
  login: string;
  name: string;
};
