import React, { useEffect } from 'react';
import { Input, Icon } from 'antd';
import { InputProps } from 'antd/lib/input';
import { useIntl } from 'react-intl';
import { useStoreActions, useStoreState } from '../store/hooks';

const PuPSearchButton: React.FC<InputProps> = props => {
  const searchValue = useStoreState(state => state.pickupPoints.searchValue);
  const { formatMessage } = useIntl();
  const { fetchList, setSearchValue } = useStoreActions(
    actions => actions.pickupPoints,
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  useEffect(() => {
    fetchList();
  }, [fetchList, searchValue]);
  return (
    <Input
      placeholder={formatMessage({ id: 'pup_definitions.search_placeholder' })}
      suffix={<Icon type='search' />}
      onChange={handleChange}
      value={searchValue}
      {...props}
    />
  );
};

export default PuPSearchButton;
