import React from 'react';
import { Icon, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { TransportDetail } from '../api/types';
import UpdateButton from './UpdateButton';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const CarrierTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { listDetail: data, isLoading } = useStoreState(
    state => state.carriers,
  );

  const { formatMessage } = useIntl();

  return (
    <Table<TransportDetail>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      rowClassName={record =>
        `c-pointer${!record.active ? ' inactive-row' : ''}`
      }
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({
            id: 'carriers.table.titles.id',
          }),
        },
        {
          dataIndex: 'name',
          title: formatMessage({
            id: 'carriers.table.titles.name',
          }),
          render: (text, { name, active }) =>
            active
              ? name
              : `${name} <${formatMessage({ id: 'common.inactive' })}>`,
        },
        {
          dataIndex: 'onlyPupDelivery',
          title: formatMessage({
            id: 'carriers.table.titles.onlyPupDelivery',
          }),
          align: 'center',
          render: (text, { onlyPupDelivery }) =>
            onlyPupDelivery ? (
              <Icon
                style={{ fontSize: '20px' }}
                type='check'
                theme='outlined'
              />
            ) : (
              <Icon
                style={{ fontSize: '20px' }}
                type='close'
                theme='outlined'
              />
            ),
        },
        {
          key: 'update-button',
          align: 'right',
          render: (text, { id }) => (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default CarrierTable;
