import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import MultiSelect from './MultiSelect';
import { useStoreState, useStoreActions } from '../store/hooks';

export interface Props {}

const WarehouseSelect: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const warehouses = useStoreState(state =>
    state.warehouses.list.filter(w => w.active),
  );
  const { warehouseIds, isLoading } = useStoreState(
    state => state.transportDefinitions,
  );
  const { setWarehouseIds, fetchList } = useStoreActions(
    actions => actions.transportDefinitions,
  );
  const fetchWarehouses = useStoreActions(
    actions => actions.warehouses.fetchList,
  );

  const handleChange = (values: number[]) => {
    setWarehouseIds(values);
  };

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    fetchList();
  }, [warehouseIds, fetchList]);
  return (
    <MultiSelect
      placeholder={formatMessage({
        id: 'transport_definitions.select_warehouse',
      })}
      loading={isLoading}
      value={warehouseIds}
      onChange={handleChange}
    >
      {warehouses
        .valueSeq()
        .toArray()
        .map(({ id, name }) => (
          <Select.Option key={id}>{name}</Select.Option>
        ))}
    </MultiSelect>
  );
};

export default WarehouseSelect;
