import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { Transport, TransportDetail } from '../api/types';
import { StoreModel } from './types';
import { transportApi } from '../api';

export const defaultCarrier: TransportDetail = {
  id: 0,
  name: '',
  active: true,
  onlyPupDelivery: false,
};

export interface CarrierStore {
  isLoading: boolean;
  list: OrderedMap<number, Transport>;
  listDetail: OrderedMap<number, TransportDetail>;
  detail: TransportDetail;
  setLoading: Action<CarrierStore, boolean>;
  setList: Action<CarrierStore, Transport[]>;
  setListDetail: Action<CarrierStore, TransportDetail[]>;
  setDetail: Action<CarrierStore, TransportDetail>;
  fetchList: Thunk<CarrierStore, void, any, StoreModel>;
  fetchCarrier: Thunk<CarrierStore, number, any, StoreModel, Promise<boolean>>;
  createCarrier: Thunk<
    CarrierStore,
    TransportDetail,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateCarrier: Thunk<
    CarrierStore,
    TransportDetail,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const carriers: CarrierStore = {
  isLoading: false,
  list: OrderedMap(),
  listDetail: OrderedMap(),
  detail: defaultCarrier,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setListDetail: action((state, payload) => {
    state.listDetail = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await transportApi.getTransportList();
      actions.setList(data);
      actions.setListDetail(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchCarrier: thunk(async (actions, id) => {
    try {
      const { data } = await transportApi.getTransportDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createCarrier: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await transportApi.createTransport(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  updateCarrier: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await transportApi.updateTransport(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default carriers;
