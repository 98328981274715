import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { Warehouse, BaseEntity, Country } from '../api/types';
import { StoreModel } from './types';
import { warehouseApi } from '../api';

export const defaultWarehouse: Warehouse = {
  id: 0,
  name: '',
  active: true,
  calendar: {} as BaseEntity,
  operationDays: [],
  stockingDelay: 0,
  country: {} as Country,
  expeditionDelay: 0,
  heliosSupply: false,
  stockCloseTime: '',
  releaseDateDelay: 0,
};

export interface WarehouseStore {
  isLoading: boolean;
  list: OrderedMap<number, Warehouse>;
  detail: Warehouse;
  setLoading: Action<WarehouseStore, boolean>;
  setList: Action<WarehouseStore, Warehouse[]>;
  setDetail: Action<WarehouseStore, Warehouse>;
  fetchList: Thunk<WarehouseStore, void, any, StoreModel>;
  createWarehouse: Thunk<
    WarehouseStore,
    Warehouse,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateWarehouse: Thunk<
    WarehouseStore,
    Warehouse,
    any,
    StoreModel,
    Promise<boolean>
  >;
  fetchWarehouse: Thunk<
    WarehouseStore,
    number,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const warehouses: WarehouseStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultWarehouse,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await warehouseApi.getWarehouseList();
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  createWarehouse: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await warehouseApi.createWarehouse(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  updateWarehouse: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await warehouseApi.updateWarehouse(value);
      actions.setLoading(false);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  fetchWarehouse: thunk(async (actions, id) => {
    try {
      const { data } = await warehouseApi.getWarehouseDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
};

export default warehouses;
