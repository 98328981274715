import React from 'react';
import { Menu, Icon, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import {
  TRANSPORT_DEFINITIONS,
  PUP_DEFINITIONS,
  CALENDARS,
  WAREHOUSES,
  COUNTRIES,
  CARRIERS,
} from '../routes/Routes';
import { useStoreState } from '../store/hooks';

const { Item, SubMenu } = Menu;

export interface Props {}

const SideBar: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const collapsed = useStoreState(state => state.general.collapsed);
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className='side-bar'
      width={230}
    >
      <div className='logo'>DDS Admin</div>
      {isLoggedIn && (
        <Menu
          theme='dark'
          mode='inline'
          selectedKeys={[pathname]}
          defaultOpenKeys={['sub1', 'sub2']}
        >
          <SubMenu
            key='sub1'
            title={
              <span>
                <Icon type='database' />
                <span>
                  <FormattedMessage id='side_bar.data_management' />
                </span>
              </span>
            }
          >
            <Item key={CALENDARS}>
              <Link to={CALENDARS}>
                <FormattedMessage id='side_bar.calendars' />
              </Link>
            </Item>
            <Item key={CARRIERS}>
              <Link to={CARRIERS}>
                <FormattedMessage id='side_bar.carriers' />
              </Link>
            </Item>
            <Item key={COUNTRIES}>
              <Link to={COUNTRIES}>
                <FormattedMessage id='side_bar.countries' />
              </Link>
            </Item>
            <Item key={WAREHOUSES}>
              <Link to={WAREHOUSES}>
                <FormattedMessage id='side_bar.warehouses' />
              </Link>
            </Item>
          </SubMenu>
          <SubMenu
            key='sub2'
            title={
              <span>
                <Icon type='car' />
                <span>
                  <FormattedMessage id='side_bar.transport_management' />
                </span>
              </span>
            }
          >
            <Item key={TRANSPORT_DEFINITIONS}>
              <Link to={TRANSPORT_DEFINITIONS}>
                <FormattedMessage id='side_bar.transport_definitions' />
              </Link>
            </Item>
            <Item key={PUP_DEFINITIONS}>
              <Link to={PUP_DEFINITIONS}>
                <FormattedMessage id='side_bar.pup_definitions' />
              </Link>
            </Item>
          </SubMenu>
        </Menu>
      )}
    </Layout.Sider>
  );
};

export default SideBar;
