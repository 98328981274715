import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NewButton from '../components/NewButton';
import CalendarTable from '../components/CalendarTable';
import CountrySelect from '../components/CountrySelect';
import { Mode } from '../store/types';
import useFormRef from '../hooks/useFormRef';
import { CalendarDetail } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { defaultCalendar } from '../store/calendars';
import CalendarForm from '../components/CalendarForm';

const CalendarPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<CalendarDetail>();
  const {
    setDetail,
    fetchCalendar,
    createCalendar,
    updateCalendar,
  } = useStoreActions(actions => actions.calendars);
  const { detail } = useStoreState(state => state.calendars);

  const { formatMessage } = useIntl();

  const handleEdit = async (id: number) => {
    await fetchCalendar(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchCalendar(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultCalendar);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    formRef!.validateFields((err, values) => {
      if (!err) {
        switch (mode) {
          case Mode.CREATE:
            createCalendar(values).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_new' }),
                );
                setVisible(false);
              }
            });
            break;
          case Mode.EDIT:
            updateCalendar(values).then(res => {
              if (res) {
                message.success(
                  formatMessage({ id: 'common.message.success_edit' }),
                );
                setVisible(false);
              }
            });
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <div className='calendar-page'>
      <CountrySelect />
      <NewButton onClick={handleNew} />
      <CalendarTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={<FormattedMessage id='calendars.detail.title' />}
        visible={isVisible}
        width={1400}
        footer={mode === Mode.VIEW ? null : undefined}
        onCancel={e => {
          e.preventDefault();
          setVisible(false);
          formRef!.resetFields();
        }}
        okText={<FormattedMessage id='common.save' />}
        onOk={handleSave}
      >
        <CalendarForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};

export default CalendarPage;
