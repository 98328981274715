import axios, { AxiosBasicCredentials } from 'axios';
import { message } from 'antd';
import MockAdapter from 'axios-mock-adapter';
import $ from 'jquery';

let username = '';
let password = '';

const axiosInstance = axios.create({
  // @ts-ignore
  baseURL: window.configuration.apiUrl,
  paramsSerializer: params => $.param(params, true),
});

const mock = new MockAdapter(axiosInstance, { delayResponse: 200 });

// @ts-ignore
if (!window.configuration.mocks) {
  mock.restore();
}

axiosInstance.interceptors.response.use(
  res => res,
  error => {
    const expectedError = error?.response?.status === 200;

    if (!expectedError) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.code === 'VALIDATION_ERROR'
      ) {
        error.response.data.validationErrors.map(
          (v: {
            code: string;
            codeDescription: string;
            field: string;
            message?: string;
          }) => {
            if (v.message) {
              message.error(v.message);
            } else {
              message.error(`${v.field}: ${v.codeDescription}`);
            }
            return v;
          },
        );
      } else if (error.response?.data?.details) {
        message.error(error.response.data.details);
      } else if (error.response?.data?.codeDescription) {
        message.error(error.response.data.codeDescription);
      } else {
        message.error(`Error! ${error.response?.status || ''}`);
      }
    }

    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(function(axiosConfig) {
  if (username !== '' && password !== '') {
    axiosConfig.auth = { username, password };
  }

  return axiosConfig;
});

function setAuth(credentials: AxiosBasicCredentials) {
  username = credentials.username;
  password = credentials.password;
}

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  patch: axiosInstance.patch,
  delete: axiosInstance.delete,
  mock,
  setAuth,
};
