import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import MultiSelect from './MultiSelect';
import { useStoreState, useStoreActions } from '../store/hooks';

export interface Props {}

const CountrySelect: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { countryIds, isLoading } = useStoreState(state => state.calendars);
  const countries = useStoreState(state => state.countries.list)
    .valueSeq()
    .toArray();
  const { setCountryIds, fetchList } = useStoreActions(
    actions => actions.calendars,
  );
  const fetchCountries = useStoreActions(
    actions => actions.countries.fetchList,
  );

  const handleChange = (values: number[]) => {
    setCountryIds(values);
  };

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    fetchList({ ids: countryIds });
  }, [countryIds, fetchList]);
  return (
    <MultiSelect
      placeholder={formatMessage({
        id: 'calendars.select_country',
      })}
      loading={isLoading}
      value={countryIds}
      onChange={handleChange}
    >
      {countries.map(({ id, code }) => (
        <Select.Option key={id} value={id}>
          {code}
        </Select.Option>
      ))}
    </MultiSelect>
  );
};

export default CountrySelect;
