import React from 'react';
import { Col, Form, Input, Row, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { TransportDetail } from '../api/types';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';

interface Props extends FormComponentProps<TransportDetail> {
  data: TransportDetail;
  mode: Mode;
}

const CarrierForm: React.FC<Props> = ({
  form: { getFieldDecorator },
  mode,
}) => {
  const isView = mode === Mode.VIEW;
  const isEdit = mode === Mode.EDIT;

  getFieldDecorator('id');
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col offset={3} span={10}>
          <Form.Item label={<FormattedMessage id='carriers.detail.active' />}>
            {getFieldDecorator('active', {
              valuePropName: 'checked',
            })(<Switch disabled={isView} />)}
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label={<FormattedMessage id='carriers.detail.onlyPupDelivery' />}
          >
            {getFieldDecorator('onlyPupDelivery', {
              valuePropName: 'checked',
            })(<Switch disabled={isView || isEdit} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='start'>
        <Col span={24}>
          <Form.Item label={<FormattedMessage id='carriers.detail.name' />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<Input disabled={isView} />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data }) =>
    transformFormData({
      ...data,
    }),
})(CarrierForm);
