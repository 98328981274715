import React, { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

export interface Props {
  years: string[];
  onCopyYears: (selectedYear: number) => void;
}

const CopyDates: React.FC<Props> = ({ years, onCopyYears }) => {
  const [isVisible, setVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  return (
    <>
      <Button
        type='primary'
        onClick={e => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        <FormattedMessage id='calendars.detail.copy_dates' />
      </Button>
      <Modal
        visible={isVisible}
        title={<FormattedMessage id='calendars.detail.copy_dates' />}
        onCancel={() => setVisible(false)}
        onOk={e => {
          e.preventDefault();
          onCopyYears(Number(selectedYear));
          setVisible(false);
        }}
      >
        <Select<string>
          style={{ width: 200 }}
          value={selectedYear}
          onChange={v => {
            setSelectedYear(v);
          }}
        >
          {years.map(v => (
            <Select.Option key={v} value={v}>
              {v}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default CopyDates;
