import { UserStore } from './user';
import { GeneralStore } from './general';
import { WarehouseStore } from './warehouses';
import { TransportDefinitionStore } from './transportDefinitions';
import { PickupPointStore } from './pickupPoints';
import { CalendarStore } from './calendars';
import { CountryStore } from './countries';
import { CarrierStore } from './carriers';

export interface StoreModel {
  user: UserStore;
  general: GeneralStore;
  warehouses: WarehouseStore;
  transportDefinitions: TransportDefinitionStore;
  pickupPoints: PickupPointStore;
  calendars: CalendarStore;
  countries: CountryStore;
  carriers: CarrierStore;
}

export enum Mode {
  VIEW,
  CREATE,
  EDIT,
}
